const LOCAL_STORAGE_TOKEN_NAME = 'c32wfeFW23DQwd23DW1Ed31544';
export function setLocalRefershToken(refreshToken: string) {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, refreshToken);
}
export function getLocalRefreshToken() {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
  if (token) {
    return token;
  }
  return '';
}

import { apiUrl } from 'app/environment-variables';
import { path } from 'app/utils/pathUtils';

function withTrailingSlash<T extends Record<string, string>>(obj: T): T {
  for (const prop of Object.keys(obj)) {
    Object.assign(obj, { [prop]: path.join(obj[prop], '/') });
  }
  return obj as T;
}

export const urls = withTrailingSlash({
  // Amper Authentication Urls
  register: path.join(apiUrl, 'api/v1/users/'),
  login: path.join(apiUrl, 'auth/'),
  changePassword: path.join(apiUrl, 'change-password/'),
  user: path.join(apiUrl, 'users/me/'),
  updateUser: path.join(apiUrl, 'users'),
  tokenRefresh: path.join(apiUrl, 'api/v1/token/refresh/'),
  invalidateToken: path.join(apiUrl, 'logout/'),
  recoverPassword: path.join(apiUrl, 'reset-password/'),
  resetPassword: path.join(apiUrl, 'reset-password/user/'),
});

import React, { FC } from 'react';
import { message } from 'antd';
import Axios from 'axios';
import { useUserData } from 'app/hooks/useUserData';
import { getUserData } from 'app/utils/userData';
import { useAmperAuth } from 'app/hooks/useAmperAuth';
import { WSUserContext } from './UserContext';
import { AmperUserProviderProps } from './models';

export const AmperUserProvider: FC<AmperUserProviderProps> = ({ children }) => {
  const [userData, setUserData, setAuthStatus] = useUserData();

  const {
    login,
    logout,
    changePassword,
    changeUserData,
    isReady,
    loading,
    setLoading,
    register,
  } = useAmperAuth(isLoggedIn => {
    if (isLoggedIn) {
      setLoading(true);
      getUserData()
        .then(udata => {
          if (udata) {
            setUserData(udata);
            setAuthStatus(true);
          }
        })
        .catch(e => {
          if (!Axios.isCancel(e)) {
            message.error('An error occurred while fetching the user data');
          }
        })
        .finally(() => setLoading(false));
    } else {
      setAuthStatus(false);
    }
  });

  const isAuthenticated = () => {
    return userData.isAuthenticated;
  };

  return (
    <WSUserContext.Provider
      value={{
        userData,
        setUserData,
        isAuthenticated,
        login,
        changePassword,
        changeUserData,
        logout,
        token: '',
        refresh: () => ({}),
        errors: [],
        loading,
        singleUnitsOnly: true,
        register,
      }}
    >
      {isReady ? children : <></>}
    </WSUserContext.Provider>
  );
};

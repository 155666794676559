import type { AxiosError } from 'axios';
import Axios from 'axios';
import { HTTPStatusCodes } from 'app/utils/httpStatuses';

function isAxiosError(e: Error | AxiosError): e is AxiosError {
  return 'isAxiosError' in e && e.isAxiosError;
}

function isAuthError(e: AxiosError) {
  if (e.code === HTTPStatusCodes.ClientError.Unauthorized) {
    return true;
  }
  if (
    e.response &&
    e.response.status.toString() === HTTPStatusCodes.ClientError.Unauthorized
  ) {
    return true;
  }
  return false;
}

function addRequestInterceptor(config: { token: { current: string } }) {
  const { token } = config;
  return Axios.interceptors.request.use(
    conf => {
      if (token.current) {
        conf.headers!.Authorization = `Bearer ${token.current}`;
      }
      return conf;
    },
    e => e,
  );
}

function addResponseInterceptor(config: {
  refreshToken: { current: string | undefined };
  onRequestError: () => void;
  tokenRefreshMethod: (rt: string) => Promise<boolean>;
}) {
  const { onRequestError, refreshToken, tokenRefreshMethod } = config;
  const refreshQueue: Set<string> = new Set();
  return Axios.interceptors.response.use(
    success => {
      refreshQueue.delete(success.request.responseURL);
      return success;
    },
    async (error: Error | AxiosError) => {
      if (isAxiosError(error) && isAuthError(error)) {
        if (refreshQueue.has(error.request.responseURL)) {
          onRequestError();
          refreshQueue.delete(error.request.responseURL);
        } else {
          refreshQueue.add(error.request.responseURL);
          if (refreshToken.current) {
            const success = await tokenRefreshMethod(refreshToken.current);
            if (success) {
              return Axios.request(error.config);
            } else {
              refreshQueue.delete(error.request.responseURL);
              onRequestError();
            }
          }
        }
      }
      return Promise.reject(error);
    },
  );
}

export function addAxiosInterceptors(options: {
  token: { current: string };
  refreshToken: { current: string | undefined };
  tokenRefreshMethod: (rt: string) => Promise<boolean>;
  onRequestError: () => void;
}): [requestInterceptorId: number, responseInterceptorId: number] {
  const { token, onRequestError, refreshToken, tokenRefreshMethod } = options;

  const requestInterceptor = addRequestInterceptor({ token });
  const responseInterceptor = addResponseInterceptor({
    onRequestError,
    refreshToken,
    tokenRefreshMethod,
  });

  return [requestInterceptor, responseInterceptor];
}

function toParts(p: string) {
  const parts = p.split('/').filter((s) => s);
  if (parts[0] === 'https:' || parts[0] === 'http:') {
    parts[0] = parts[0] + '/';
  }
  return parts;
}

export const path = {
  join: (...args: string[]) => {
    const parts = args.map((path) => toParts(path));
    return (
      (args[0][0] === '/' ? '/' : '') +
      parts
        .reduce((acc, arr) => {
          acc.push(...arr);
          return acc;
        }, [])
        .join('/') +
      (args[args.length - 1][args[args.length - 1].length - 1] === '/'
        ? '/'
        : '')
    );
  },
  basename: (path: string) => {
    return toParts(path).slice(-1)[0] || path;
  },
  location: (path: string) => {
    return toParts(path).slice(0, -1).join('/');
  },
  split: (path: string) => {
    const parts = toParts(path);
    if (parts.length > 1) {
      return [parts.slice(0, -1).join('/'), parts.slice(-1)[0]];
    } else {
      return undefined;
    }
  },
};

export namespace HTTPStatusCodes {
  /**
   * Status codes in the range 100 - 199
   *
   * This class of status code indicates a provisional response, consisting only
   * of the Status-Line and optional headers, and is terminated by an empty line.
   * There are no required headers for this class of status code. Since HTTP/1.0
   * did not define any 1xx status codes, servers MUST NOT send a 1xx response to
   * an HTTP/1.0 client except under experimental conditions.
   *
   * A client MUST be prepared to accept one or more 1xx status responses prior to
   * a regular response, even if the client does not expect a 100 (Continue) status
   * message. Unexpected 1xx status responses MAY be ignored by a user agent.
   *
   * Proxies MUST forward 1xx responses, unless the connection between the proxy and
   * its client has been closed, or unless the proxy itself requested the generation
   * of the 1xx response. (For example, if a proxy adds a "Expect: 100-continue"
   * field when it forwards a request, then it need not forward the corresponding
   * 100 (Continue) response(s).)
   *
   * *Source:*
   * @link https://www.restapitutorial.com/httpstatuscodes.html
   */
  export enum Informational {
    /**
     * @summary
     * This interim response indicates that everything so far is OK and that the client
     * should continue the request, or ignore the response if the request is already finished.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The client SHOULD continue with its request. This interim response is used to inform
     * the client that the initial part of the request has been received and has not yet been
     * rejected by the server. The client SHOULD continue by sending the remainder of the
     * request or, if the request has already been completed, ignore this response. The server
     * MUST send a final response after the request has been completed.
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     */
    Continue = '100',
    /**
     * @summary
     * This code is sent in response to an Upgrade request header from the client, and
     * indicates the protocol the server is switching to.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The server understands and is willing to comply with the client's request, via
     * the Upgrade message header field, for a change in the application
     * protocol being used on this connection. The server will switch protocols to
     * those defined by the response's Upgrade header field immediately after the empty
     * line which terminates the 101 response.
     *
     * The protocol SHOULD be switched only when it is advantageous to do so. For example,
     * switching to a newer version of HTTP is advantageous over older versions, and
     * switching to a real-time, synchronous protocol might be advantageous when delivering
     * resources that use such features.
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     */
    SwitchingProtocols = '101',
    /**
     * @summary
     * This code indicates that the server has received and is processing the request,
     * but no response is available yet.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The 102 (Processing) status code is an interim response used to inform the client
     * that the server has accepted the complete request, but has not yet completed it.
     * This status code SHOULD only be sent when the server has a reasonable expectation
     * that the request will take significant time to complete. As guidance, if a method
     * is taking longer than 20 seconds (a reasonable, but arbitrary value) to process
     * the server SHOULD return a 102 (Processing) response. The server MUST send a final
     * response after the request has been completed.
     *
     *
     * Methods can potentially take a long period of time to process, especially methods
     * that support the Depth header. In such cases the client may time-out the connection
     * while waiting for a response. To prevent this the server may return a 102 (Processing)
     * status code to indicate to the client that the server is still processing the method.
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     */
    Processing = '102',
    /**
     * @summary
     * This status code is primarily intended to be used with the Link header, letting the
     * user agent start preloading resources while the server prepares a response.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     */
    EarlyHints = '103',
  }

  /**
   * Status codes in the range 200 - 299
   *
   * This class of status code indicates that the client's request was successfully
   * received, understood, and accepted.
   *
   * *Source:*
   * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
   */
  export enum Success {
    /**
     * The request has succeeded. The meaning of the success depends on the HTTP method:
     *
     * - GET: The resource has been fetched and is transmitted in the message body.
     * - HEAD: The entity headers are in the message body.
     * - PUT or POST: The resource describing the result of the action is transmitted in
     *   the message body.
     * - TRACE: The message body contains the request message as received by the server.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     */
    OK = '200',
    /**
     * @summary
     * The request has succeeded and a new resource has been created as a result. This
     * is typically the response sent after POST requests, or some PUT requests.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The request has been fulfilled and resulted in a new resource being created.
     * The newly created resource can be referenced by the URI(s) returned in the entity
     * of the response, with the most specific URI for the resource given by a Location
     * header field. The response SHOULD include an entity containing a list of resource
     * characteristics and location(s) from which the user or user agent can choose the
     * one most appropriate. The entity format is specified by the media type given in the
     * Content-Type header field. The origin server MUST create the resource before
     * returning the 201 status code. If the action cannot be carried out immediately, the
     * server SHOULD respond with 202 (Accepted) response instead.
     *
     * A 201 response MAY contain an ETag response header field indicating the current value
     * of the entity tag for the requested variant just created.
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     */
    Created = '201',
    /**
     * @summary
     * The request has been received but not yet acted upon. It is noncommittal, since there
     * is no way in HTTP to later send an asynchronous response indicating the outcome of
     * the request. It is intended for cases where another process or server handles the
     * request, or for batch processing.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The request has been accepted for processing, but the processing has not been completed.
     * The request might or might not eventually be acted upon, as it might be disallowed
     * when processing actually takes place. There is no facility for re-sending a status code
     * from an asynchronous operation such as this.
     *
     * The 202 response is intentionally non-committal. Its purpose is to allow a server to
     * accept a request for some other process (perhaps a batch-oriented process that is only
     * run once per day) without requiring that the user agent's connection to the server
     * persist until the process is completed. The entity returned with this response SHOULD
     * include an indication of the request's current status and either a pointer to a status
     * monitor or some estimate of when the user can expect the request to be fulfilled.
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     */
    Accepted = '202',
    /**
     * @summary
     * This response code means the returned meta-information is not exactly the same as is
     * available from the origin server, but is collected from a local or a third-party copy.
     * This is mostly used for mirrors or backups of another resource. Except for that specific
     * case, the "200 OK" response is preferred to this status.
     *
     * *Source:*
     * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
     *
     * @description
     * The returned metainformation in the entity-header is not the definitive set as available
     * from the origin server, but is gathered from a local or a third-party copy. The set
     * presented MAY be a subset or superset of the original version. For example, including
     * local annotation information about the resource might result in a superset of the
     * metainformation known by the origin server. Use of this response code is not required and
     * is only appropriate when the response would otherwise be 200 (OK).
     *
     * *Source:*
     * @link https://www.restapitutorial.com/httpstatuscodes.html
     *
     */
    NonAuthoritativeInformation = '203',
    /**
     * @summary
     * There is no content to send for this request, but the headers may be useful. The user-agent
     * may update its cached headers for this resource with the new ones.
     *
     * @description
     * The server has fulfilled the request but does not need to return an entity-body, and might
     * want to return updated metainformation. The response MAY include new or updated metainformation
     * in the form of entity-headers, which if present SHOULD be associated with the requested variant.
     *
     * If the client is a user agent, it SHOULD NOT change its document view from that which caused the
     * request to be sent. This response is primarily intended to allow input for actions to take place
     * without causing a change to the user agent's active document view, although any new or updated
     * metainformation SHOULD be applied to the document currently in the user agent's active view.
     *
     * The 204 response MUST NOT include a message-body, and thus is always terminated by the first
     * empty line after the header fields.
     */
    NoContent = '204',
    /**
     * @summary
     * Tells the user-agent to reset the document which sent this request.
     *
     * @description
     * The server has fulfilled the request and the user agent SHOULD reset the document view which
     * caused the request to be sent. This response is primarily intended to allow input for
     * actions to take place via user input, followed by a clearing of the form in which the input
     * is given so that the user can easily initiate another input action. The response
     * MUST NOT include an entity.
     */
    ResetContent = '205',
    PartialContent = '206',
    MultiStatus = '207',
    AlreadyReported = '208',
    ImUsed = '226',
  }
  export enum ClientError {
    BadRequest = '400',
    Unauthorized = '401',
    PaymentRequired = '402',
    Forbidden = '403',
    NotFound = '404',
    MethodNotAllowed = '405',
    NotAcceptable = '406',
    ProxyAuthRequired = '407',
    RequestTimeout = '408',
    Conflict = '409',
    Gone = '410',
    LengthRequired = '411',
    PreconditionFailed = '412',
    RequestTooLarge = '413',
    RequestURITooLong = '414',
    UnsupportedMedia = '415',
    RequestOutOfRange = '416',
    ExpectationFailed = '417',
    ImATeapot = '418',
    Unprocessable = '422',
    Locked = '423',
    FailedDependency = '424',
    UpgradeRequired = '426',
    PreconditionRequired = '428',
    TooManyRequests = '429',
    RequestHeadersTooLarge = '431',
    NoResponse = '444',
    RetryWith = '449',
    UnavailableForLegalReasons = '451',
    ClientClosedRequest = '499',
  }
  export enum ServerError {
    InternalError = '500',
    NotImplemented = '501',
    BadGateway = '502',
    ServiceUnavailable = '503',
    GatewayTimeout = '504',
    HttpUnsupportedVersion = '505',
    VariantAlsoNegotiates = '506',
    InsufficientStorage = '507',
    LoopDetected = '508',
    BandwidthLimitExceeded = '509',
    NotExtended = '510',
    NetworkAuthRequired = '511',
    NetworkReadTimeout = '598',
    NetworkConnectTimeout = '599',
  }
}

import React from 'react';
import { userData } from 'app/contexts/UserContext/models.d';
import { objectEntries } from 'app/utils/objectUtils';

export type AmperUserData = Omit<userData, 'isAuthenticated'>;

export const useUserData = (): [
  userData,
  (ud: Partial<AmperUserData>) => void,
  (isAuthenticated: boolean) => void,
] => {
  const [id, setId] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const updateState = (ud: Partial<AmperUserData>) => {
    const setters = {
      id: setId,
      first_name: setFirstName,
      last_name: setLastName,
      username: setUsername,
      phone: setPhone,
      company_name: setCompanyName,
      email: setEmail,
    };

    for (const [key, value] of objectEntries(ud)) {
      if (value !== undefined && value !== null) {
        const setter = setters[key] as (v: typeof value) => void;
        if (setter) {
          setter(value);
        }
      }
    }
  };

  return [
    {
      id,
      first_name: firstName,
      last_name: lastName,
      username,
      phone,
      company_name: companyName,
      email,
      isAuthenticated,
    },
    updateState,
    setIsAuthenticated,
  ];
};

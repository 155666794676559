/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'app/utils/loadable';

export const BehavioralPage = lazyLoad(
  () => import('./index'),
  module => module.BehavioralPage,
);

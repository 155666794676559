import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { message } from 'antd';
import { SearchContextInterface } from './models';
import { getFooterLinks, FooterLinksI } from 'app/adapters/FooterLinks';
import { getNavBar, NavBarI } from 'app/adapters/NavBar';
import { getDisclaimer, DisclaimerI } from 'app/adapters/Disclaimer';
import { getSocialLinks, SocialLinksI } from 'app/adapters/SocialLinks';

export const SearchContext = React.createContext<SearchContextInterface>({
  isSearchBar: false,
  showSearchBar: () => ({}),
  closeSearchBar: () => ({}),
  setPortfolioId: () => ({}),
  portfolioId: null,
  searchValue: '',
  setSearchValue: () => ({}),
  menuItems: [],
  footerLinks: [],
  disclaimer: [],
  socialLinks: [],
});

export const SearchContextProvider = ({ children }) => {
  const [menuItems, setMenuItems] = useState<NavBarI[]>([]);
  const [disclaimer, setDisclaimer] = useState<DisclaimerI[]>([]);
  const [socialLinks, setSocialLinks] = useState<SocialLinksI[]>([]);
  const [footerLinks, setFooterLinks] = useState<FooterLinksI[]>([]);
  const [portfolioId, setPortfolioId] = useState<number | null>(null);
  const [isSearchBar, setSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const showSearchBar = () => {
    setSearchBar(true);
  };

  const closeSearchBar = () => {
    setSearchBar(false);
  };

  const getFooterItems = async () => {
    try {
      const { data } = await getFooterLinks();

      setFooterLinks(data.results);
    } catch (error) {
      if (!Axios.isCancel(error)) {
        message.error('An error occurred while fetching the footer data');
      }
    }
  };

  const getSocialLinksData = async () => {
    try {
      const { data } = await getSocialLinks();

      setSocialLinks(data.results);
    } catch (error) {
      if (!Axios.isCancel(error)) {
        message.error('An error occurred while fetching the social links data');
      }
    }
  };

  const getDisclaimerData = async () => {
    try {
      const { data } = await getDisclaimer();

      setDisclaimer(data.results);
    } catch (error) {
      if (!Axios.isCancel(error)) {
        message.error('An error occurred while fetching the disclaimer data');
      }
    }
  };

  const getNavBarData = async () => {
    try {
      const { data } = await getNavBar();

      setMenuItems(data.results);
    } catch (error) {
      if (!Axios.isCancel(error)) {
        message.error('An error occurred while fetching the nav data');
      }
    }
  };

  useEffect(() => {
    getNavBarData();
    getFooterItems();
    getDisclaimerData();
    getSocialLinksData();
  }, []);

  return (
    <SearchContext.Provider
      value={{
        isSearchBar,
        showSearchBar,
        closeSearchBar,
        searchValue,
        setSearchValue,
        menuItems,
        footerLinks,
        portfolioId,
        setPortfolioId,
        disclaimer,
        socialLinks,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

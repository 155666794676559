/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'app/utils/loadable';

export const FAQPage = lazyLoad(
  () => import('./index'),
  module => module.FAQPage,
);

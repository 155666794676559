import { isEqual } from 'lodash';
import { useRef } from 'react';

class TrackedReference<T> {
  protected callbackStack: Array<{
    id: number;
    callback: (refVal: T, reomve?: () => void) => void;
    removeAfterCall: boolean;
  }> = [];
  protected reference: T;
  protected lastId = 0;

  constructor(ref: T) {
    this.reference = ref;
  }

  addListener(
    callback: (refVal: T, remove?: () => void) => void,
    removeAfterCall = false,
  ) {
    const id = this.lastId++;
    this.callbackStack.push({
      id: id,
      callback: callback,
      removeAfterCall: removeAfterCall,
    });
    return id;
  }

  removeListener(id: number) {
    this.callbackStack = this.callbackStack.filter(entry => entry.id !== id);
  }

  get current(): T {
    return this.reference;
  }

  set current(newVal: T) {
    if (!isEqual(newVal, this.current)) {
      this.reference = newVal;
      for (const c of this.callbackStack) {
        c.callback(newVal, () => {
          this.removeListener(c.id);
        });
      }
      this.callbackStack
        .filter(e => e.removeAfterCall)
        .forEach(e => this.removeListener(e.id));
    }
  }
}

export function useTrackedRef<T>(val: T) {
  const tf = useRef<TrackedReference<T>>(new TrackedReference(val));
  return tf.current;
}

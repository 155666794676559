import Axios from 'axios';
import { urls } from 'app/adapters/auth-urls';
import { userData } from 'app/contexts/UserContext/models.d';
import type { AmperUserData } from 'app/hooks/useUserData';

export function getUserData() {
  return new Promise<AmperUserData | null>(resolve => {
    Axios.get<userData>(urls.user)
      .then(response => {
        if (response.data) {
          const {
            id,
            first_name,
            last_name,
            username,
            email,
            phone,
            company_name,
          } = response.data;
          resolve({
            id,
            first_name,
            last_name,
            username,
            email,
            phone,
            company_name,
          });
        } else {
          resolve(null);
        }
      })
      .catch(error => {
        resolve(null);
      });
  });
}

import { message } from 'antd';
import Axios from 'axios';
import { urls } from 'app/adapters/auth-urls';
import type { AmpAuthResponse, AuthorizationResponse } from './models';

const initialAmpAuth: AmpAuthResponse = {
  access: '',
  refresh: '',
};

export function amperRefreshToken(refresh: string) {
  return new Promise<AmpAuthResponse>(resolve => {
    Axios.post<AmpAuthResponse>(urls.tokenRefresh, { refresh })
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        resolve(initialAmpAuth);
      });
  });
}

export function amperRegister(
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  phone: string,
  companyName: string,
  promoCode: string,
) {
  return Axios.post(
    urls.register,
    {
      username,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      phone,
      company_name: companyName,
      promo_code: promoCode,
    },
    {},
  )
    .then(response => {
      if (response.status === 201) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
}

export function amperLogIn(username: string, password: string) {
  return new Promise<AuthorizationResponse>(resolve => {
    Axios.post<AmpAuthResponse>(urls.login, {
      password,
      username,
    })
      .then(response => {
        resolve({ data: response.data });
      })
      .catch(e => {
        resolve({ data: initialAmpAuth, error: e.response?.data });
      });
  });
}

export function amperChangePassword(
  id: string,
  password: string,
  new_password: string,
  repeat_new_password: string,
) {
  return Axios.post(urls.changePassword, {
    id,
    password,
    new_password,
    repeat_new_password,
  });
}

export function amperChangeUserData(
  id: string,
  firstName: string,
  lastName: string,
  phone: string,
  companyName: string,
) {
  return Axios.put(`${urls.updateUser}${id}`, {
    id,
    first_name: firstName,
    last_name: lastName,
    phone,
    company_name: companyName,
  })
    .then(response => {
      if (response.status === 200) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
}

export function amperLogOut(refresh: string) {
  return Axios.post(urls.invalidateToken, { refresh_token: refresh }).catch(
    e => {
      if (!Axios.isCancel(e)) {
        message.error(e.response?.data?.detail);
      }
    },
  );
}

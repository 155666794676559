export type Primitive = string | number | boolean | symbol;

export type AnyBaseType =
  | undefined
  | null
  | Primitive
  | AnyObject
  | AnyBaseType[];

export type AnyObject = object;

export function objectKeys<T extends AnyObject>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function objectEntries<T extends AnyObject>(
  obj: T
): [keyof T, T[keyof T]][] {
  return (Object.entries(obj) as unknown) as [keyof T, T[keyof T]][];
}

type DeepPartial<O extends AnyObject> = {
  [K in keyof O]?: O[K] extends AnyObject ? DeepPartial<O[K]> : O[K];
};

export function isRegularObject(obj: unknown): obj is AnyObject {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function objectCopyAssign<O extends AnyObject>(
  obj: O,
  assignValues: DeepPartial<O>
) {
  const newObj: O = { ...obj };
  for (const [key, val] of objectEntries(assignValues)) {
    if ((!(key in newObj) || !isRegularObject(val)) && val) {
      Object.assign(newObj, { [key]: val });
      continue;
    }
    Object.assign(newObj, {
      [key]: objectCopyAssign(
        (newObj[key] as unknown) as AnyObject,
        val as AnyObject
      ),
    });
  }
  return newObj;
}

export function hasProperty<K extends string | number | symbol>(
  obj: AnyObject,
  key: K
): obj is Record<K, AnyBaseType> {
  return key in obj;
}

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PrivateRouteProps } from './models.d';

const NotAuthRedirect: React.FC = () => {
  return <Redirect to="/page-not-found" />;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  fallback: Fallback,
  authed,
  ...rest
}) => {
  const renderWhenNotAuthed = Fallback ? <Fallback /> : <NotAuthRedirect />;
  return (
    <Route
      {...rest}
      render={props =>
        authed && Component ? <Component {...props} /> : renderWhenNotAuthed
      }
    />
  );
};

export default PrivateRoute;

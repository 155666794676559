/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'app/utils/loadable';

export const FundamentalPage = lazyLoad(
  () => import('./index'),
  module => module.FundamentalPage,
);

import React, { createContext, FC } from 'react';
import { AmperUserProvider } from './UserContextProvider';
import { UserContextInterface, WSUserProviderProps } from './models';

export const WSUserContext = createContext<UserContextInterface>({
  userData: {
    isAuthenticated: false,
    id: '',
    first_name: '',
    last_name: '',
    phone: '',
    company_name: '',
    email: '',
    username: '',
  },
  login: () => Promise.reject('UserContext is not initialized yet.'),
  changeUserData: () => Promise.reject('UserContext is not initialized yet.'),
  changePassword: () => Promise.reject('UserContext is not initialized yet.'),
  logout: () => ({}),
  refresh: () => ({}),
  setUserData: () => ({}),
  register: () => Promise.reject('UserContext is not initialized yet.'),
  token: '',
  singleUnitsOnly: true,
  isAuthenticated: () => false,
  loading: false,
});

export const WSUserProvider: FC<WSUserProviderProps> = ({ children }) => {
  return <AmperUserProvider>{children}</AmperUserProvider>;
};
